<template>
  <div class="district-page">
    <div class="detail-bg">
      <div class="district-title">中国（湖南）自由贸易试验区知识产权服务中心</div>
      <div class="district-img">
        <el-image class="district-image" fit="cover" :src="require('@/assets/centerintro.png')"></el-image>
      </div>
      <div class="content-container">
        <p class="intro" v-for="(item, index) in contents" :key="index">{{ item }}</p>
      </div>
    </div>
    <div class="article-footer"></div>
  </div>
</template>

<script>

import store from '@/store'

export default {
  name: "",
  data() {
    return {
      contents: [
          '中国（湖南）自由贸易试验区知识产权服务中心隶属于长沙市经开区市场监督管理局（知识产权局），中心整合国家、省、市知识产权公共资源，打造集知识产权创造、运用、保护、管理、服务为一体的“一站式”服务平台。',
          '中心以基础核心业务为支撑，建设国家知识产权海外纠纷应对指导中心长沙分中心、国家知识产权局专利局专利审查协作湖北中心长沙工作站、湖南省知识产权综合服务（长沙经开区）分中心、中国（湖南）自由贸易试验区长沙片区知识产权公共服务中心等重要平台。',
          '中心下设综合管理部、信息服务部和维权援助部3个部门，共有工作人员17人，其中专利审查员4人，专利维权律师2人，专利代理师4人，商标代理师2人。常驻3家知识产权服务机构，7家流动知识产权服务机构。', 
          '中心提供知识产权快速授权、快速确权、快速维权、运营导航等27项知识产权公共服务。实现省级知识产权服务事项办理不出园区，线上信息化平台链接全省服务资源。探索“知识产权保护+区块链”的应用场景，建立知识产权前置存证平台，实现企业知识产权全流程保护。',
          '作为国家知识产权局服务长沙经开区创新主体的重要窗口和长沙经开区开展知识产权工作的有力抓手，中国（湖南）自由贸易试验区知识产权服务中心结合园区的定位与需求，探索符合园区优势产业发展的工作机制，致力于将中心建成支撑园区“一城两港两中心”和湖南省“三高四新”战略发展的全要素知识产权服务平台。']
    };
  },
  mounted() {},
  methods: {},
  beforeRouteEnter(to, from, next) {
    store.commit('navbar/SET_POSITION_BOTTOM')
    let pageIndex = { activeIndex: '7'}
    store.commit('navbar/SET_ACTIVEINDEX', pageIndex)
    next()
  },
};
</script>

<style lang="stylus" scoped>

@media only screen and (min-width: 600px) {
.district-page {
  background #f6f6f6
  display flex
  flex-direction column
}

.navbar {
  flex-grow 0
  flex-shrink 0
  height 365px
}

.detail-bg {
  margin 0 auto
  width 800px
  background white
  padding 0px 55px
  flex-grow 1
  flex-shrink 0
}

.district-title {
  margin 72px 0px 54px 0px
  font-size 20px
  font-weight 600
  color #818181
  text-align center
  width 100%
}

.district-img {
  display flex
  justify-content center
  margin-bottom 54px
}

.intro  {
  font-size 14px
  color #818181
  line-height 28px
  text-indent 30px
}


.content-container {
  margin-bottom 50px
}

.article-footer {
  height 80px
}

}

// mobile


@media only screen and (max-width: 600px) {
.district-page {
  background #f6f6f6
  min-height 100%
  display flex
  flex-direction column
}

.navbar {
  flex-grow 0
  flex-shrink 0
  height 365px
}

.detail-bg {
  margin 0 auto
  background white
  padding 0px 25px
  flex-grow 1
  flex-shrink 0
}

.district-title {
  margin 72px 0px 54px 0px
  font-size 20px
  font-weight 600
  color #818181
  text-align center
  width 100%
}

.district-img {
    margin-bottom 54px
}

.intro  {
  font-size 14px
  color #818181
  line-height 28px
  text-indent 30px
}

.content-container {
  margin-bottom 50px
}

}
</style>